import type { ObjectDirective } from 'vue'
import * as logger from '~/utils/logging'

type InterpolateElement = HTMLElement & {
  $componentUpdated?: () => void
  $destroy?: () => void
}

export const vInterpolate: ObjectDirective<InterpolateElement> = {
  mounted(el) {
    const links = Array.from(el.getElementsByTagName('a')).filter((link) => {
      const href = link.getAttribute('href')
      if (!href) return false

      return isInternalLink(href)
    })

    addListeners(links)

    el.$componentUpdated = () => {
      removeListeners(links)
      nextTick(() => addListeners(links))
    }

    el.$destroy = () => removeListeners(links)
  },
  updated: (el) => el.$componentUpdated?.(),
  beforeUnmount: (el) => el.$destroy?.(),
}

function navigate(event: Event) {
  const target = event.target as HTMLAnchorElement
  const href = target.getAttribute('href')

  if (!href) return

  event.preventDefault()
  navigateTo(href)
}

function addListeners(links: HTMLAnchorElement[]) {
  links.forEach((link) => link.addEventListener('click', navigate, false))
}

function removeListeners(links: HTMLAnchorElement[]) {
  links.forEach((link) => link.removeEventListener('click', navigate, false))
}

function isInternalLink(href?: string) {
  if (!href) return false
  // ignore links with file extensions
  if (/.*\.\w+/.test(href)) return false

  const isInternal = href?.startsWith('/')
  if (window?.location?.origin) {
    try {
      const url = new URL(href, window.location.origin)
      return isInternal || url.origin === window.location.origin
    } catch {
      logger.error('Failed to parse URL:', href)
    }
  }
  return isInternal
}
